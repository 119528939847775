import { useCallback, useState } from 'react';
import { purify } from '@vfit/shared/themes';
import { Button, ImageAtoms, Svg } from '@vfit/shared/atoms';
import { IErrorProps } from './errorGenericV2.models';
import * as S from './errorGenericV2.style';

export const ErrorGenericV2: React.FC<IErrorProps> = ({
  title,
  subtitle,
  description,
  descriptionOnTop,
  action,
  actionText,
  customAction,
  customActionText,
  icon,
  variant = 'sunrise',
  hideTitle,
  isApp
}) => {
  const [disabled, setDisabled] = useState(false);

  const handleAction = useCallback(() => {
    setDisabled(true);
    action?.();
  }, [action]);

  const handleCustomAction = useCallback(() => {
    setDisabled(true);
    customAction?.();
  }, [customAction]);

  const [pDescription, pSubtitle, pTitle] = purify([description, subtitle, title]);

  return (
    <S.Container isApp={isApp}>
      <S.MessageDiv>
        {!hideTitle && pTitle && (
          <S.Title variant={variant} dangerouslySetInnerHTML={{ __html: pTitle }} />
        )}
        {(icon && (
          <S.ImageContainer>
            <S.ImageWrapper>
              <ImageAtoms image={icon} nameCard="iconError" style={{ objectFit: 'contain' }} />
            </S.ImageWrapper>
          </S.ImageContainer>
        )) || (
          <S.ImageWrapper>
            <Svg name="warningBig" height="120" width="120" />
          </S.ImageWrapper>
        )}
        <S.BodyWrap descriptionOnTop={descriptionOnTop} variant={variant}>
          {pSubtitle && (
            <S.Subtitle variant={variant} dangerouslySetInnerHTML={{ __html: pSubtitle }} />
          )}
          {pDescription && (
            <S.Description variant={variant} dangerouslySetInnerHTML={{ __html: pDescription }} />
          )}
        </S.BodyWrap>

        {(actionText || customActionText) && (
          <S.InsideButtonContainer variant={variant}>
            {actionText && (
              <Button
                variant={variant === 'sunrise' ? 'mva:primary' : 'primary'}
                onClick={handleAction}
                disabled={disabled}
              >
                {actionText}
              </Button>
            )}
            {customActionText && (
              <Button
                variant={variant === 'sunrise' ? 'mva:alt1' : 'secondary'}
                onClick={handleCustomAction}
                disabled={disabled}
              >
                {customActionText}
              </Button>
            )}
          </S.InsideButtonContainer>
        )}
      </S.MessageDiv>
    </S.Container>
  );
};


